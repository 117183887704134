.landing-feature-2 {
  // background: #f6f8fd;
  // height: 759px;
  height: 800px;
  display: flex;
  justify-content: flex-start;
  background-image: linear-gradient(#fff9, #fff9),
    url('../../../assets/images/landing/feature-2-map.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  overflow: hidden;

  @media only screen and (max-width: 718px) {
    height: 600px;
  }

  @media only screen and (max-width: 500px) {
    height: 520px;
  }

  & .landing-feature-2--img__wrapper {
    // transform: scale(0.8) translate(80px, 50px);
    transform: translate(83px, 188px);
    & > img {
      transform: scale(0.8);
    }
    @media only screen and (max-width: 1263px) {
      transform: translate(114px, 219px);
      & > img {
        transform: scale(0.7);
      }
    }
    @media only screen and (max-width: 1122px) {
      transform: translate(135px, 249px);
      & > img {
        transform: scale(0.6);
      }
    }
    @media only screen and (max-width: 1092px) {
      transform: translate(102px, 249px);
    }
    @media only screen and (max-width: 986px) {
      transform: translate(0px, 249px);
    }
    @media only screen and (max-width: 774px) {
      transform: translate(-100px, 290px);

      & > img {
        transform: scale(0.5);
      }
    }
    @media only screen and (max-width: 718px) {
      transform: translate(-150px, 120px);
      & > img {
        transform: scale(0.4);
      }
    }
    /*  @media only screen and (max-width: 686px) {
      transform: translate(114px, 50px);
    }
    @media only screen and (max-width: 600px) {
      transform: translate(0px, 50px);
    } */
    @media only screen and (max-width: 515px) {
      display: none;
    }
  }
  &--content {
    margin-left: 100px;
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @media only screen and (max-width: 984px) {
      margin-left: 60px;
    }
    @media only screen and (max-width: 774px) {
      margin-left: 30px;
    }
    @media only screen and (max-width: 515px) {
      width: 90%;
      margin: 0 auto;
    }

    & > h2 {
      color: #222222;
      font-size: 38px;
      display: inline-block;
      @media only screen and (max-width: 1285px) {
        width: 120%;
      }
      @media only screen and (max-width: 1217px) {
        width: 130%;
      }
      @media only screen and (max-width: 1166px) {
        width: 150%;
      }
      @media only screen and (max-width: 1110px) {
        width: 180%;
        font-size: 30px;
      }
      @media only screen and (max-width: 943px) {
        width: 220%;
      }
      @media only screen and (max-width: 684px) {
        width: 100%;
      }
    }
    & > p {
      margin-top: 30px;
      font-size: 24px;
      color: #222222;
      display: inline-block;
      @media only screen and (max-width: 1200px) {
        width: 135%;
      }
      @media only screen and (max-width: 1166px) {
        width: 150%;
      }
      @media only screen and (max-width: 1110px) {
        font-size: 16px;
      }
      @media only screen and (max-width: 943px) {
        width: 195%;
      }
      @media only screen and (max-width: 769px) {
        width: 160%;
      }
      @media only screen and (max-width: 684px) {
        width: 100%;
      }
    }
    &__background {
      height: 100%;
      width: 100px;
      transform: rotate(180deg);
      background: url('../../../assets/images/register/bg-graphic.svg');
      background-size: contain;
      background-position: 0 0;
      background-repeat: no-repeat;
    }
    & > a {
      display: block;
      width: 100%;
      text-align: center;

      @media only screen and (max-width: 684px) {
        height: 98px;
      }
    }
    &__button {
      margin-top: 100px !important;
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15) !important;
      padding: 16px 78px !important;
      background-color: #3c99fc !important;
      color: white !important;

      @media only screen and (max-width: 1200px) {
        padding: 16px 40px !important;
      }
      @media only screen and (max-width: 1136px) {
        width: max-content;
      }

      @media only screen and (max-width: 500px) {
        display: block;
        margin: 50px auto 51px !important;
        width: calc(100% - 20px);
      }

      /* @media only screen and (max-width: 345px) {
        padding: 12px 30px !important;
      } */
    }
  }

  &--background {
    background: url('../../../assets/images/landing/feature-1.jpg'), white;
    background-position: center;
    background-repeat: no-repeat;
    width: 45%;
  }
}

/* margin-left: 77px !important;
    margin-top: 100px !important;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15) !important;
    padding: 10px 75px !important;
} */

/* .landing-hero--button.Button-sizeLarge-4, .landing-hero--button.jss4 */
