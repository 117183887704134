.landing-feature-4 {
  // height: 632px;
  background: #f6f8fd;
  background-image: url('../../../assets/images/landing/feature-4.png');
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;
  // background-size: 800px;
  // background-size: 944px auto;
  // background-size: auto;
  padding-top: 90px;
  background-size: auto 66.2%;

  @media only screen and (max-width: 1335px) {
    background-size: auto 72.2%;
  }

  @media only screen and (max-width: 1227px) {
    background-size: auto 66.2%;
  }

  @media only screen and (max-width: 1173px) {
    background-size: auto 63%;
  }

  @media only screen and (max-width: 1092px) {
    // background-size: auto 64%;
  }
  @media only screen and (max-width: 889px) {
    background-size: contain;
    // background-position-y: 92%;
    background-position-x: center;
  }
  /* 
  @media only screen and (max-width: 1025px) {
    // background-size: 450px;
    background-size: auto 50%;
  } */
  /* @media only screen and (max-width: 889px) {
    background-size: auto 40%;

    // background-size: 400px;
  } */
  @media only screen and (max-width: 725px) {
    // background-size: 350px;
  }
  @media only screen and (max-width: 1330px) {
    // background-size: auto 65.2%;
  }
  @media only screen and (max-width: 500px) {
    background-size: 100%;
    background-image: url('../../../assets/images/landing/feature-4.png');
    background-position-x: center;
    background-position-y: top;
  }

  & > div {
    display: flex;
    align-items: flex-start;

    padding: 91px 89px 220px;
    margin: 0 auto;
    @media only screen and (max-width: 1280px) {
      padding: 91px 89px 150px;
    }
    /* @media only screen and (max-width: 1173px){
      padding: ;
    } */
    @media only screen and (max-width: 889px) {
      // padding: 71px 49px;
      padding: 71px 49px 360px;
    }

    @media only screen and (max-width: 500px) {
      margin-right: 50px;
      display: none;
    }
  }
  & > &--top-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0;

    @media only screen and (max-width: 500px) {
      margin: 0 auto;
      padding-top: 250px;
    }
    @media only screen and (max-width: 450px) {
      padding-top: 190px;
    }

    @media only screen and (max-width: 412px) {
      padding-top: 160px;
    }

    @media only screen and (max-width: 350px) {
      padding-top: 120px;
    }

    & > h2 {
      font-size: 38px;
      font-weight: bold;
      color: #10233e;
      letter-spacing: 0.36px;
      line-height: 48px;
      @media only screen and (max-width: 500px) {
        font-size: 30px;
        margin-top: 32px;
      }
    }
    & > p {
      font-size: 28px;
      font-weight: normal;
      color: #222;
      letter-spacing: 0.69px;
      @media only screen and (max-width: 500px) {
        font-size: 25px;
        margin-top: 20px;
      }
      & > span {
        font-weight: bolder;
      }
    }
    &__button {
      &.Button-sizeLarge-4,
      &.jss4 {
        margin-left: 77px !important;
        margin-top: 100px !important;
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15) !important;
        padding: 10px 75px !important;
        margin-bottom: 180px;
        display: none;
        @media only screen and (max-width: 500px) {
          display: block;
          margin: 50px auto 51px !important;
          width: calc(100% - 28px);
        }
      }
      & > .Button-label-1 {
        font-size: 18px !important;
        font-weight: 500 !important;
        text-transform: uppercase;
        color: white !important;

        @media only screen and (max-width: 1000px) {
          font-size: 16px !important;
        }
      }
    }
  }
  &--left-section {
    width: 50%;
    margin-right: 50px;
    padding: 0;
    max-width: 625px;
    @media only screen and (max-width: 889px) {
      width: 100%;
    }
    & > p {
      font-size: 20px;
      // width: 106%;
      width: 655px;
      letter-spacing: 0.49px;
      @media only screen and (max-width: 810px) {
        width: auto;
      }
    }
  }

  &--right-section {
    margin-top: 10px;
    & > a {
      text-decoration: none;
    }
    & > p {
      font-size: 23px;
      color: #222222;
      font-weight: normal;
    }
  }

  &--heading {
    font-size: 38px;
    color: #222222;
  }
  &--list {
    list-style-type: none;
    font-size: 20px;
    color: #222222;
    margin-top: 45px;
    // width: 85%;
    width: 563px;
    @media only screen and (max-width: 810px) {
      width: auto;
    }
    & > li {
      display: flex;
      margin-bottom: 26px;
      letter-spacing: 0.49px;
      & > div {
        margin-left: 10px;
        & > span {
          font-weight: bold;
        }
      }
    }
  }

  &--button {
    &.Button-sizeLarge-4,
    &.jss4 {
      display: block !important;
      margin: 60px auto !important;
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15) !important;
      padding: 16px 124px !important;
    }
    & > .Button-label-1,
    & > .jss3 {
      font-size: 18px !important;
      font-weight: 500 !important;
    }
  }
}
