.add-card-modal {
  background-color: white;
  width: 837px;
  border-radius: 8px;
  @media only screen and (max-width: 830px) {
    width: 100%;
  }
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--header {
    height: 80px;
    padding: 28px 27px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #ddd;
    color: #1a315b;

    @media only screen and (max-width: 500px) {
      height: 60px;
      padding: 16px;
    }

    & > h2 {
      font-size: 22px;
    }

    & > svg {
      transform: scale(0.82);
    }
  }
  &--content {
    padding: 24px 56px 0px;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 620px) {
      padding: 45px 44px;
    }
    @media only screen and (max-width: 400px) {
      padding: 25px 24px;
    }
  }
  &--form {
    &--row {
      display: flex;
      align-items: flex-end;
      margin: 10px 0;

      @media only screen and (max-width: 550px) {
        margin: 4px 0;
      }
      @media only screen and (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start;
        margin: 0;
      }

      .MuiFormControl-root {
        @media only screen and (max-width: 500px) {
          margin-bottom: 20px !important;
        }
        &:nth-of-type(2n) {
          margin-left: 50px;
          // margin-bottom: ;
          @media only screen and (max-width: 580px) {
            margin-left: 30px;
          }
          @media only screen and (max-width: 540px) {
            margin-left: 20px;
          }
        }
      }
      img {
        margin-left: 50px;
        @media only screen and (max-width: 580px) {
          margin-left: 30px;
        }
        @media only screen and (max-width: 540px) {
          margin-left: 20px;
        }
        @media only screen and (max-width: 500px) {
          display: none;
        }
      }
      .MuiFormControl-marginNormal {
        margin-bottom: 0;
        margin-top: 0;
      }
      & .expiry-date {
        @media only screen and (max-width: 500px) {
          width: 100% !important;
        }
      }

      & .cvc {
        @media only screen and (max-width: 500px) {
          width: 60% !important;
          margin-left: 0 !important;
          position: relative;
          &::after {
            content: '';
            height: 30px;
            width: 100px;
            background-image: url('../../../assets/icons/credit-cards/cvv-info.jpg');
            background-repeat: no-repeat;
            position: absolute;
            right: -120px;
            top: 10px;

            @media only screen and (max-width: 380px) {
              right: -110px;
            }
          }
        }
      }
    }
  }
  &--content > div:not(&--form--row) {
    @media only screen and (max-width: 500px) {
      display: flex;
      flex-direction: column;
      padding-top: 43px !important;
      padding-bottom: 0px !important;
      padding-right: 0 !important;
      width: 100%;
    }
    @media only screen and (max-width: 360px) {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
    & > button:first-of-type {
    }
    & > button:last-of-type {
      @media only screen and (max-width: 500px) {
        margin-top: 12px;
        margin-left: 0 !important;
      }
    }
  }
}
