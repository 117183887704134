.filter-header {
  // background-color: rgba(255, 255, 255, 0.72);
  /* display: flex;
  justify-content: center;
  margin-top: 35px; */
  // padding: 49px;
  // width: 80%;
  // margin: 0 auto;
  font-weight: 400;
  max-width: 1172px;
  margin: 97px auto 12px;

  @media only screen and (max-width: 1196px) {
    width: 90%;
  }
  /*   @media only screen and (max-width: 665px) {
    width: 98%;
  } */

  @media only screen and (max-width: 900px) {
    display: none;
  }

  &--content {
    &__container {
      // max-width: 70%;
      // display: flex;
      // flex-direction: row;
      // justify-content: space-around;
      // align-items: flex-end;
      /* margin: 0 auto; */
      /* display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end; */
    }

    &__row {
      display: flex;
      justify-content: space-between;

      &--left {
        // background-color: red;
        max-width: 70%;
        display: inline-flex;

        .field-wrapper {
          // border: 1px solid #c4cdd5;
          border-radius: 10px;
          // padding: 0 10px;
          width: 355px;
          margin-right: 22px;
          // margin-right: 10px;
          // .MuiInputBase-root {
          //   background-color: white;
          // }

          @media only screen and (max-width: 868px) {
            margin-right: 10px;
          }

          & .MuiInput-underline:before {
            display: none;
          }
          & .MuiInput-underline:after {
            display: none;
          }

          label + .MuiInput-formControl {
            margin-top: 10px;
          }
          & .MuiOutlinedInput-input {
            padding: 15px 14px;
          }
        }

        .date-wrapper {
          border: 1px solid #c4cdd5;
          background-color: #fff;
          border-radius: 3px;
          margin: 0 7.5px;
          height: 50px;
          padding: 12px 0px 0px 10px;
          width: 190px;
          @media only screen and (max-width: 868px) {
            margin: 0 10px;
          }

          & .MuiInputBase-root {
            font-size: 14px;
          }

          & .MuiFormHelperText-root.Mui-error {
            display: none;
          }

          & .MuiFormControl-marginNormal {
            margin-top: 0;
            margin-bottom: 0;
          }
          & .MuiInput-underline:before {
            display: none;
          }
          & .MuiInput-underline:after {
            display: none;
          }

          .MuiIconButton-root {
            padding: 12px 12px 0 6px;
            margin-top: -12px;
          }
        }
      }

      &--right {
        .MuiSelect-selectMenu {
          min-width: 160px;
        }
        .MuiInputBase-root {
          background: white;
        }
      }

      & > :nth-child(1) {
        // margin-top: 16px;
        // margin-bottom: 8px;
      }
      /* & > :nth-child(2) {
        width: 216px;
      } */
    }
    // &__submit {
    //   margin-top: 16px;
    //   margin-bottom: 8px;
    //   // &.MuiButtonBase-root {
    //   //   padding: 6.5px 37px;
    //   //   background-color: #4a90e2;
    //   //   max-height: 50px;
    //   //   margin-left: 5px;
    //   //   border-radius: 4px;

    //   @media only screen and (max-width: 785px) {
    //     padding: 6.5px 20px;
    //     margin-right: 10px;
    //   }
    // }

    // &.MuiButton-root:hover {
    //   background-color: #5d95d5;
    // }
    // & .MuiButton-label {
    //   color: white;
    //   font-weight: 400;
    // }
  }

  &__reset {
    // &.MuiButtonBase-root {
    //   padding: 0;
    //   width: 190px;
    //   background-color: white;
    //   border: 1px solid #4a90e2;
    //   max-height: 50px;
    //   margin-left: 16px;
    //   border-radius: 4px;
    //   font-weight: 400;

    //   @media only screen and (max-width: 1200px) {
    //     margin-left: 12px;
    //     width: 212px;
    //   }
    //   @media only screen and (max-width: 1100px) {
    //     width: 240px;
    //   }
    //   @media only screen and (max-width: 950px) {
    //     width: 320px;
    //   }

    //   @media only screen and (max-width: 785px) {
    //     // padding: 6.5px 20px;
    //     width: 360px;
    //     margin-right: 10px;
    //   }
    // }
    // & .MuiButton-label {
    //   color: #4a90e2;
    //   // font-weight: bold;
    // }
    // }
  }
}
