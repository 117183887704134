@import '../../../../styles/variables.scss';

.payment {
  max-width: 800px;
  width: 100%;
  // margin: 0 auto 20px;
  background-color: #ffffff;

  /* max-width: 800px;
  width: 100%;
  margin: 0 auto 20px;
  background-color: #ffffff; */

  @media only screen and (max-width: 1200px) {
    width: 50%;
  }
  @media only screen and (max-width: 714px) {
    margin: 20px 0;
    padding: 0 10px;
    width: 100%;
  }
  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;

    @media only screen and (max-width: 714px) {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }

    & .commission {
      @media only screen and (max-width: 900px) {
        width: 300px;
      }
      @media only screen and (max-width: 800px) {
        width: 260px;
      }
      @media only screen and (max-width: 714px) {
        width: 96%;
        margin: 20px auto;
      }
      &__content {
        padding: 14px 26px 13px;
      }
    }
  }

  &-content {
    border-radius: 8px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
  }

  &-card {
    position: relative;
    padding: 40px;
    border-bottom: 1px solid #bec7d1;

    display: flex;
    align-items: center;
    @media only screen and (max-width: 1200px) {
      padding: 20px;
    }

    @media only screen and (max-width: 714px) {
      padding: 20px;
    }

    &--lean {
      padding: 30px 40px;

      @media only screen and (max-width: 714px) {
        padding: 20px;
      }

      & > p {
        margin: 0 55px;
      }

      & > svg {
        margin-left: auto;
        margin-right: 10px;

        @media only screen and (max-width: 714px) {
          margin-right: 0;
        }
      }
    }

    & > button {
      margin-left: auto;

      @media only screen and (max-width: 714px) {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    & > img {
      margin: 0 50px;
      @media only screen and (max-width: 1200px) {
        margin: 0 20px 0 0;
        width: 70px;
        // width: 40px;
        // height: auto;
      }

      @media only screen and (max-width: 714px) {
        height: auto;
        width: 40px;
        margin: 0 20px 0 0;
      }
    }

    & .MuiSvgIcon-root {
      font-size: 30px;
    }

    &
      .PrivateRadioButtonIcon-root-185.PrivateRadioButtonIcon-checked-187
      .PrivateRadioButtonIcon-layer-186 {
      transform: scale(1.4);
    }

    &-details {
      color: #333333;

      display: flex;
      flex-direction: column;
    }
  }

  &-chevron {
    &.MuiSvgIcon-root {
      transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
    &--rotate {
      transform: rotate(90deg);
    }
  }

  &-btns {
    padding: 40px 40px 30px;

    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 714px) {
      padding: 60px 20px 20px;
    }
  }

  &-form {
    &-content {
      padding: 0 40px 30px;
      border-bottom: 1px solid #bec7d1;

      @media only screen and (max-width: 714px) {
        padding: 20px;
      }
    }

    &-cvc-expiry__container {
      width: 50%;

      display: flex;
      align-items: flex-start;

      @media only screen and (max-width: 714px) {
        width: 100%;
      }

      & .expiry-date {
        flex-grow: 2;
        margin-right: 20px;
        max-width: 50%;
      }

      & .cvc {
        flex-grow: 1;
        max-width: 40%;
        margin-top: 16px;
      }
    }

    &-btns {
      padding: 50px 40px 40px;

      @media only screen and (max-width: 714px) {
        padding: 60px 20px 20px;
      }

      display: flex;
      justify-content: space-between;
    }
  }
}

.no-border {
  border: none;
}

.guest-add-card-form {
  width: 600px;
  padding: 30px 50px;
  background: white;
  box-shadow: 0 4px 12px 0 rgba(26, 49, 91, 0.61);
  @media only screen and (max-width: 1000px) {
    width: 500px;
    padding: 15px 25px;
  }

  @media only screen and (max-width: 840px) {
    width: 450px;
  }
  @media only screen and (max-width: 740px) {
    width: 400px;
  }

  @media only screen and (max-width: 700px) {
    width: 95%;
    margin-bottom: 50px;
  }

  &.gift-card-form {
    width: 100%;
  }

  &__content {
  }
  &__heading {
  }
  &__row {
    display: flex;
    @media only screen and (max-width: 500px) {
      flex-direction: column;
    }

    &__lower {
      align-items: flex-end;
    }

    /* &__lower &__item {
      width: 30%;
    } */
  }
  &__item {
    width: 50%;
    &:not(:last-child) {
      margin-right: 12px;
    }

    @media only screen and (max-width: 500px) {
      width: 100%;
      &:not(:last-child) {
        margin-right: 0;
      }
    }
    & img {
      display: none;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }
}
