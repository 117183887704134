.amazon-terms-modal {
  width: 896px;
  border-radius: 8px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__header {
    height: 80px;
    padding: 28px 27px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #ddd;
    color: #000;
    @media only screen and (max-width: 350px) {
      padding: 24px 15px;
    }

    & > h2 {
      font-size: 22px;
      font-weight: normal;
      @media only screen and (max-width: 350px) {
        font-size: 18px;
      }
    }

    & > svg {
      transform: scale(0.82);
    }
  }

  &__content {
    padding: 57px 56px;
    font-size: 16px;
    color: #000;
    line-height: normal;
    font-weight: normal;
    @media only screen and (max-width: 600px) {
      padding: 30px 29px;
    }
    @media only screen and (max-width: 350px) {
      padding: 30px 15px;
    }
    // display: flex;
    // flex-direction: column;
    & > p {
      // margin-bottom: 20px;
    }
    & > ul {
      list-style: none;
      line-height: 1.94;
    }
  }
}
