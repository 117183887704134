.currency-input {
  //   width: 100%;
  &__container {
    width: 100%;
  }
  &__field {
    display: flex;
    align-items: flex-end;

    &--first {
      width: 121px;
      margin-right: 15px !important;
      @media only screen and (max-width: 660px) {
        width: 200px;
      }
    }

    &--second {
      width: 80px;
      margin-left: 15px !important;
      margin-right: 15px !important;
      @media only screen and (max-width: 660px) {
        width: 120px;
      }
    }

    span {
      margin-bottom: 3px;
      display: block;
    }
  }
}
