.edit-card-modal {
  background-color: white;
  //   height: 566px;
  width: 837px;
  //   padding: 0 10px 40px;
  border-radius: 8px;

  @media only screen and (max-width: 830px) {
    width: 100%;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--header {
    height: 80px;
    padding: 28px 27px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #dddddd;
    color: #1a315b;

    @media only screen and (max-width: 500px) {
      height: 60px;
      padding: 16px;
    }

    & > h2 {
      font-size: 22px;
    }

    & > svg {
      transform: scale(0.82);
    }
  }

  &--content {
    padding: 57px 56px;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 620px) {
      padding: 45px 44px;
    }
    @media only screen and (max-width: 400px) {
      padding: 25px 24px;
    }
  }

  &--form {
    &--row {
      display: flex;
      align-items: flex-end;
      margin: 10px 0;
      @media only screen and (max-width: 550px) {
        margin: 4px 0;
      }
      @media only screen and (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start;
        margin: 0;
      }

      &:first-of-type {
        .MuiFormControl-root {
          &:nth-of-type(2n) {
            margin-bottom: 20px;
            @media only screen and (max-width: 550px) {
            }
          }
        }
      }
      &:last-of-type .MuiFormControl-root:nth-of-type(2n) {
        @media only screen and (max-width: 500px) {
          width: 60%;
          position: relative;
          &::after {
            content: '';
            height: 30px;
            width: 100px;
            background-image: url('../../../assets/icons/credit-cards/cvv-info.jpg');
            background-repeat: no-repeat;
            position: absolute;
            right: -120px;
            top: 10px;

            @media only screen and (max-width: 380px) {
              right: -110px;
            }
          }
        }
      }
      .MuiFormControl-root {
        margin-bottom: 20px;
        @media only screen and (max-width: 500px) {
          margin-bottom: 20px !important;
        }
        &:nth-of-type(2n) {
          margin-left: 50px;
          margin-bottom: 0px;
          @media only screen and (max-width: 550px) {
            margin-left: 20px;
          }
          @media only screen and (max-width: 500px) {
            margin-left: 0;
          }
        }
      }
      img {
        margin-left: 50px;
        @media only screen and (max-width: 550px) {
          margin-left: 20px;
        }
        @media only screen and (max-width: 500px) {
          display: none;
        }
      }

      .MuiFormControl-marginNormal {
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }
  & > div:not(&--header):not(&--content) {
    @media only screen and (max-width: 500px) {
      display: flex;
      flex-direction: column;
      // padding-right: 20px !important;
      padding-left: 30px;
    }
    @media only screen and (max-width: 360px) {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
    & > button:first-of-type {
    }
    & > button:last-of-type {
      @media only screen and (max-width: 500px) {
        margin-top: 12px;
        margin-left: 0 !important;
      }
    }
  }
}
