@import './variables.scss';
/* ==============================
            Page reset
==============================*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* ==============================
            Variables
==============================*/
:root {
  --white: white;
}

/* ==============================
            Body
==============================*/
body {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 300;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

form {
  .MuiFormLabel-root.Mui-focused {
    color: #1a315b;
  }
  .MuiInput-underline:after {
    border-bottom: 2px solid #1a315b;
  }
}

/* ==============================
            Global
==============================*/
.link {
  color: #009988;
  display: inline-block;
  cursor: pointer;
  border-bottom: 1px solid #009988;
}

/* =============================
              Font
===============================*/
@font-face {
  font-family: ArialRoundedMTBold;
  src: url('../assets/fonts/arial-rounded.ttf');
}
