.commission {
  width: 23%;
  height: 100%;
  border-radius: 8px;
  margin-left: 25px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: rgba(255, 255, 255, 0.61);
  @media only screen and (max-width: 900px) {
    width: 40%;
  }
  @media only screen and (max-width: 700px) {
    margin-bottom: 30px;
    margin-left: 0px;
    width: 100%;
    padding: 20;
  }
  &__header {
    border-radius: 8px 5px 0px 0px;
    height: 86px;
    color: #4a90e2;
    @media only screen and (max-width: 700px) {
      height: 46px;
    }
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & > h2 {
      font-size: 14px;
      color: #4a90e2;
      font-weight: bold;
      margin-left: 17px;
    }
  }
  &__content {
    padding: 0px 20px 8px;
    display: flex;
    flex-direction: column;

    &__row {
      display: flex;
      flex-direction: column;
      margin-bottom: 1px;
      @media only screen and (max-width: 700px) {
        flex-direction: row;
      }
    }

    &__label {
      font-size: 14px;
      color: #666666;
      @media only screen and (max-width: 700px) {
        font-size: 14px;
        width: 70%;
      }
      &.sum {
        font-size: 18px;
        margin-top: 0px;
        text-align: right;
        @media only screen and (max-width: 700px) {
          font-size: 14px;
          width: 70%;
          text-align: left;
          margin-top: 0px;
          color: #1a315b;
          font-weight: 500;
        }
      }
    }

    &__value {
      color: #1a315b;
      font-size: 14px;
      @media only screen and (max-width: 700px) {
        font-size: 14px;
        width: 30%;
        text-align: right;
      }
      &.sum {
        font-size: 18px;
        text-align: right;
        font-weight: bold;
      }
    }
  }
}
