.tooltip {
  position: relative;
  background: white;
  width: 200px;
  font-size: 10px;
  padding: 10px 12px;
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  white-space: pre-wrap;
}

.tooltip::after {
  content: '';
  display: block;
  border: none;
  height: 0;
  width: 0;
  position: absolute;
  bottom: -20px;
  left: 35px;
  border-bottom: 10px;
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent transparent;
  filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.2));
}
