.edit-payment__plus {
  font-weight: 400;
  font-size: 34px;
  color: #1a315b;
  padding: 3px 11px;
  line-height: 1.25;
  user-select: none;
  &__container {
    display: inline-block;
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 rgba(26, 49, 91, 0.8);
    background-color: #ffffff;
    transition: all 0.3s;
    position: absolute;
    top: 115px;
    right: 100px;

    @media only screen and (max-width: 500px) {
      right: 32px;
    }
    @media only screen and (max-width: 400px) {
      right: 14px;
    }

    &:hover {
      cursor: pointer;
      box-shadow: 0 3px 5px 0 rgba(26, 49, 91, 1);

      &::before {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -30px;
        transform: translate(-50%, -50%);
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;

        border-bottom: 12px solid #1a315b;
      }

      &::after {
        position: absolute;
        left: 50%;
        bottom: -80px;
        transform: translate(-50%, -50%);
        width: 140px;
        height: 40px;
        color: white;
        background-color: #1a315b;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: normal;
        content: 'Aggiungi carta';
      }
    }
  }
}

.edit-payment-details {
  &__list-container {
    width: 100%;
    .radio-list {
      width: 100%;
      & .MuiFormGroup-root {
        & > div {
          @media only screen and (max-width: 670px) {
            padding: 10px !important;
          }
        }
      }
      & .exsisting-card {
        @media only screen and (max-width: 570px) {
          padding: 10px 12px;
        }
        @media only screen and (max-width: 370px) {
          padding: 10px 8px 10px 0;
        }

        &__content {
          & > img {
            @media only screen and (max-width: 570px) {
              margin-right: 20px;
            }
            @media only screen and (max-width: 500px) {
              display: none;
            }
          }
        }
      }
      & .MuiFormControlLabel-root {
        @media only screen and (max-width: 570px) {
          margin-left: 0;
        }
        @media only screen and (max-width: 500px) {
          margin-right: 0;
        }
      }
    }
    @media only screen and (max-width: 750px) {
      padding: 0;
      width: 104%;
      margin-left: -2%;
    }
  }
}
