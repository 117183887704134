.change-password {
  background-color: #fff;
  width: 100%;
  height: 380px;
  border-radius: 6px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);

  @media only screen and (max-width: 500px) {
    width: 104%;
    margin-left: -2%;
    min-height: calc(100vh - 70px - 41.8px - 48px - 100px - 48px);
    height: 100%;
  }

  &--header {
    height: 70px;
    border-bottom: 2px solid #d1d8f599;
    padding-top: 18px;
    & > h2 {
      font-size: 18px;
      font-weight: bold;
      color: #686868;
      margin-left: 28px;
    }
  }
  &--form {
    position: relative;
    padding: 35px 55px;
    @media only screen and (max-width: 600px) {
      padding: 35px 45px;
    }
    @media only screen and (max-width: 520px) {
      padding: 30px 40px;
    }
    @media only screen and (max-width: 500px) {
      padding: 50px 40px 74px;
    }
    @media only screen and (max-width: 350px) {
      padding: 50px 30px 74px;
    }
    & > button {
      position: absolute;
      right: 31px;
      bottom: 0;

      @media only screen and (max-width: 500px) {
        bottom: 0px;
        right: 50%;
        transform: translate(50%, -50%);
        width: calc(90% - 75px) !important;
        padding: 10px 15px;
      }
    }
    &__row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 45px;

      @media only screen and (max-width: 500px) {
        flex-direction: column;
        margin-bottom: 0;
      }
    }
    &__item {
      display: flex;
      flex-direction: column;
      width: 48%;

      @media only screen and (max-width: 500px) {
        width: 100%;
        margin-bottom: 46px;
      }
    }

    .MuiInputBase-root {
      font-size: 12px;
    }
    .MuiInputLabel-root {
      @media only screen and (max-width: 640px) {
        font-size: 14px;
      }
      @media only screen and (max-width: 588px) {
        font-size: 12px;
      }
      @media only screen and (max-width: 500px) {
        font-size: 16px;
      }
    }
  }
}
