@import '../../../styles/variables.scss';

.my-payment-card {
  position: relative;
  background-color: #fff;
  height: 135px;
  border-radius: 6px;
  box-shadow: 0 3px 15px 0 #e3e3e3;
  display: flex;
  align-items: center;
  padding: 20px;
  display: flex;

  @media only screen and (max-width: $sm) {
    height: 90px;
    cursor: pointer;

    &:active {
      background-color: #e6e6e6;
    }
  }

  &--icon {
    @media only screen and (max-width: $sm) {
      transform: scale(0.8);
    }

    &__container {
      width: 127px;
      height: 100%;
      border-right: 1px solid #f5f7fa;
      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: $sm) {
        width: 100px;
      }
    }
  }
  & > h5 {
    font-size: 16px;
    font-weight: 600;
    margin-left: 20px;
    color: #1a315b;

    @media only screen and (max-width: $sm) {
      font-size: 14px;
    }

    @media only screen and (max-width: $sm) {
      display: none;
    }
  }
}
