@import '../../styles/variables.scss';
.dashboard-page {
  background-color: #f5f7fa;
  padding-top: $navbar-height;
  &-content {
    & > h1 {
      text-align: center;
      display: block;
      margin: 40px auto;
      color: #1a315b;

      @media only screen and (max-width: $md) {
        font-size: 20px;
      }
    }
  }
  &-services {
    display: flex;
    justify-content: space-evenly;
    margin: 52px auto;
  }
  &-my-payments {
    display: flex;
    justify-content: center;
    & > .my-payment-card:nth-child(1) {
      margin-right: 31px;
    }
  }
  &-last-payments {
    margin-bottom: 50px;
    & > h2 {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #1a315b;
      font-size: 20px;
      font-weight: 500;
      margin: 76px auto;

      @media only screen and (max-width: $sm) {
        margin: 50px auto;
      }
      /* lines */
      &::before,
      &::after {
        content: '';
        display: block;
        height: 1px;
        width: 20%;
        background-color: #dddddd;
        margin: auto 31px;

        @media only screen and (max-width: $sm) {
          display: none;
        }
      }
    }
  }
}
