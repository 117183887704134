@import '../../../styles/variables.scss';

.service-card {
  background-color: #fff;
  height: 244px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  @media only screen and (max-width: $sm) {
    cursor: pointer;

    &:active {
      background-color: #e6e6e6;
    }
    &.is-disabled {
      background-color: #eee;
    }
  }

  & > h4 {
    font-size: 20px;
    color: #1a315b;
    text-align: center;

    @media only screen and (max-width: $md) {
      font-size: 18px;
    }

    @media only screen and (max-width: $sm) {
      font-size: 16px;
    }
  }
  &--image {
    &__container {
      height: 81px;
      width: 81px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: rgba(40, 221, 203, 0.05);
      margin: 20px 0;
    }
  }
}
