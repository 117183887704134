@import '../../../styles/variables.scss';

.last-payment-card {
  position: relative;
  color: #222;
  background-color: #fff;
  height: 200px;
  width: 95%;
  border-radius: 6px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
  padding: 30px 20px;
  margin: 5px auto;
  cursor: pointer;
  &--title,
  &--date,
  &--amount {
    font-size: 16px;
    font-weight: 500;

    @media only screen and (max-width: $sm) {
      font-size: 14px;
    }
  }
  &--amountContainer {
    overflow: hidden;
  }

  &--amount {
    float: left;
  }

  &--euro {
    float: left;
  }
  &--date-and-amount__container {
    display: flex;
    justify-content: space-between;

    margin: 17px auto 23px auto;
  }
  &--beneficiary {
    &__heading {
      font-size: 14px;
      font-weight: 500;
      color: #4a4a4a;

      @media only screen and (max-width: $sm) {
        font-size: 13px;
      }
    }
    &__content {
      font-size: 15px;
      font-weight: 500;

      @media only screen and (max-width: $sm) {
        font-size: 14px;
      }
    }
  }
  &--right-arrow {
    position: absolute;
    right: 11px;
    bottom: 16px;
    cursor: pointer;
  }
  &--type-of-service {
    position: absolute;
    right: 15px;
    top: 13px;
    display: inline-block;
    padding: 3px 8px;
    border-radius: 50px;
    font-size: 9px;
    color: black;
    font-weight: 500;
  }
}

.last-payment-carousel--arrows {
  font-size: 32px;
  font-weight: 500;
}
