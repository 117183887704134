.gift-card-sidebar {
  // width: 348px;
  // height: 476px;
  height: 100%;
  border-radius: 6px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  min-width: 300px;
  margin-left: 15px;
  text-align: left;
  padding: 5px 40px;
  margin-right: 20px;
  @media only screen and (max-width: 1140px) {
    padding: 0px 25px;
  }
  @media only screen and (max-width: 700px) {
    height: 100%;
    margin-top: 20px;
  }
  @media only screen and (max-width: 660px) {
    height: auto;
    padding: 0px 22px;
    margin-bottom: 20px;
    margin-right: 0;
    min-width: 318px;
    margin-left: 0px;
    text-align: center;
  }

  & > img {
    /* max-height: 200px;
    max-width: 84%;`
    object-fit: scale-down;
    margin-top: 40px; */
    max-width: 190px;
    margin: 0 auto;
    object-fit: contain;
    height: 20vh;
    @media only screen and (max-width: 660px) {
      height: 17vh;
    }

    &.Linkem {
      height: 130px;
    }
  }

  &__brand {
    color: #575757;
    font-size: 28px;
    letter-spacing: 0.26px;
    color: #575757;
    margin-top: 10px;
    margin-bottom: 30px;
    @media only screen and (max-width: 1130px) {
      font-size: 24px;
    }
    @media only screen and (max-width: 700px) {
      font-size: 20px;
      margin-top: 46px;
    }
    @media only screen and (max-width: 660px) {
      margin-top: 20px;
      font-size: 28px;
      margin-bottom: 12px !important;
    }
  }

  &__amount {
    color: #575757;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0.31px;
    text-align: left;
    @media only screen and (max-width: 1130px) {
      font-size: 28px;
    }
    @media only screen and (max-width: 700px) {
      font-size: 24px;
    }
    @media only screen and (max-width: 660px) {
      font-size: 28px;
    }
  }
  & > p {
    @media only screen and (max-width: 1130px) {
      font-size: 24px !important;
    }
    @media only screen and (max-width: 700px) {
      font-size: 20px !important;
    }
    @media only screen and (max-width: 660px) {
      margin-bottom: 24px !important;
    }
  }
  &__commission {
    &__row {
      display: flex;
      flex-direction: column;
      margin-bottom: 2px;
      text-align: left;
      &.sum {
        align-items: flex-end;
        text-align: left;
      }
    }
    &__label {
      font-size: 14px;
      color: #666;
      text-align: left;
      @media only screen and (max-width: 660px) {
        font-size: 18px;
      }

      &.sum {
        font-size: 20px;
        text-align: left;
      }
    }
    &__value {
      font-size: 14px;
      color: #1a315b;
      font-weight: normal;
      text-align: left;
      @media only screen and (max-width: 660px) {
        font-size: 18px;
      }
      &.sum {
        font-size: 30px;
        text-align: right;
        font-weight: bold;
      }
    }
  }
}
