@import '../../../../styles/variables.scss';

.final {
  margin: 0 auto 20px;
  @media only screen and (max-width: 700px) {
    max-width: 100%;
    width: 100%;
  }
  &.guest {
    width: 100%;
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 700px) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  &.guest &-content {
    padding: 50px;
    margin-right: 60px;
    @media only screen and (max-width: 1000px) {
      padding: 30px;
      margin-right: 0;
      width: 60%;
    }
    @media only screen and (max-width: 700px) {
      margin-top: 20px;
    }
    @media only screen and (max-width: 500px) {
      padding: 24px;
    }
  }

  @media only screen and (max-width: $sm) {
    padding: 10px;
  }

  &-content {
    text-align: center;
    padding: 50px 10px 40px;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: $sm) {
      padding: 30px 10px 40px;
    }

    & > h2 {
      font-size: 20px;
      color: #1a315b;
    }

    & > div {
      max-width: 100%;
      margin: 40px 0 50px;
    }

    & > button {
      max-width: 250px;

      @media only screen and (max-width: $sm) {
        max-width: 100%;
      }
    }
  }
}
