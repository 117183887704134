.account-management {
  background: #fff;
  width: 100%;
  height: 266px;
  border-radius: 6px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
  @media only screen and (max-width: 500px) {
    height: 100%;
  }
  &--header {
    height: 70px;
    border-bottom: 2px solid #d1d8f599;
    padding-top: 18px;
    & > h2 {
      font-size: 18px;
      font-weight: bold;
      color: #686868;
      margin-left: 28px;
    }
  }
  &--content {
    position: relative;
    padding: 43px 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 500px) {
      flex-direction: column;
      padding: 33px 28px;

      & > button {
        margin-top: 16px;
        width: 80% !important;
      }
    }

    & > span {
      color: #686868;
      font-weight: normal;
    }
  }
}
