.landing-feature-3 {
  background: #fff;
  height: 476px;
  display: flex;
  justify-content: center;
  align-items: center;
  &--content {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > h2 {
      color: #222222;
      font-size: 38px;
      display: inline-block;
    }
    & > p {
      margin-top: 30px;
      font-size: 24px;
      color: #222222;
      display: inline-block;
    }
  }

  &--background {
    background: url('../../../assets/images/landing/feature-2.jpg'), white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 40%;
    height: 100%;
  }
}
