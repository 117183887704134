@import '../../styles/variables.scss';
.navbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: $navbar-height;
  margin: 0 auto;
  background-image: white;
  padding: 0 1em;
  box-shadow: 0px 0px 12px #e4e4e480;

  &--container {
    width: 100%;
    /* max-width: 1440px; */
    background-color: #10233e;
    position: fixed;
    z-index: 99;
    box-shadow: -6px 7px 5px -4px rgba(255, 255, 255, 0.5);
    @media only screen and (max-width: 500px) {
      box-shadow: none;
    }
  }
  &--logo {
  }

  &--content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // width: 50%;
    min-width: fit-content;
  }

  &--links {
    color: #fff;
    &-container {
      width: 300px;
      height: 25px;
      display: flex;
      justify-content: space-around;
      margin-right: 30px;

      @media only screen and (max-width: 690px) {
        display: none;
      }
    }

    &:hover {
      cursor: pointer;
      &::after {
        content: '';
        display: block;
        // background-color: #28ddcb;
        background-color: #fff;
        height: 2px;
        width: 100%;
      }
    }
  }
  &--buttons {
    & > a {
      text-decoration: none;
      @media only screen and (max-width: 500px) {
        & > button {
          width: 130px !important;
        }
      }
    }
    & > button:nth-of-type(1) {
      margin-right: 10px;
    }
  }

  &--user-icon {
    color: #1a315b;
    cursor: pointer;
    text-shadow: 0 2px rgba(158, 158, 158, 1);
    height: 42px;
  }
}

.logged-in-user-menu {
  position: absolute;
  right: 20px;
  top: $navbar-height;
  background: #fff;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  padding: 10px 24px;
  span {
    cursor: pointer;
    display: block;
    color: #1a315b;
    padding: 10px;
    &:hover {
      color: #4a90e2;
    }
  }
}
