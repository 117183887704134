.payment-description-modal {
  background-color: white;
  // height: 366px;
  width: 837px;
  border-radius: 8px;
  box-shadow: 0 4px 12px 0 #00000080;
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--header {
    height: 80px;
    padding: 28px 27px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #dddddd;
    color: #000;
    & > h2 {
      font-weight: normal;
      font-size: 20px;
    }

    & > svg {
      transform: scale(0.82);
    }
  }

  &--content {
    // padding: 40px 56px 60px;
    display: flex;
    flex-direction: column;

    /* @media only screen and (max-width: 500px) {
      padding: 20px 28px 30px;
    } */

    &--upper {
      justify-content: space-between;
      padding: 30px 110px;
      overflow: hidden;

      @media only screen and (max-width: 500px) {
        padding: 9px 30px;
      }

      span {
        font-size: 14px;
        color: #999;
        font-weight: normal;
      }

      img {
        width: 45px;
        @media only screen and (max-width: 500px) {
          display: none;
        }
      }

      &__left {
        float: left;
        & > div {
          @media only screen and (max-width: 500px) {
            margin-left: 0 !important;
          }
        }

        &--date {
          font-weight: bold;
          color: #333;
          font-size: 16px;
        }

        &--timestamp {
          font-size: 16px;
          font-weight: bold;
          margin-left: 70px;
          @media only screen and (max-width: 500px) {
            margin-left: 31px;
          }
        }
      }

      &__right {
        float: right;
        & > p {
          font-size: 25px;
          font-weight: 400;
        }
      }
    }

    &--lower {
      border-top: 1px solid #ddd;
      display: flex;
      align-items: flex-end;
      padding: 41px 36px;
      @media only screen and (max-width: 500px) {
        padding: 30px 20px;
      }
      @media only screen and (max-width: 360px) {
        padding: 30px 16px;
      }
      /*  @media only screen and (max-width: 300px) {
        padding: 30px 20px;
      } */
      &__left {
        @media only screen and (max-width: 500px) {
          display: none;
        }
      }

      &__right {
        margin-left: 33px;
        @media only screen and (max-width: 500px) {
          margin-left: 16px;

          & > h6 {
            margin-left: 40px;
          }

          & > p {
            position: relative;
            margin-left: 40px;
            &::before {
              display: block;
              font-family: FontAwesome;
              content: '\f05a';
              position: absolute;
              left: -40px;
              color: #4a90e2;
              font-size: 32px;
            }
          }
          & > a {
            & > p {
              margin-left: 40px;
            }
          }
        }
        @media only screen and (max-width: 360px) {
          margin-left: 12px;
        }
      }
    }
  }
  &--row {
    display: flex;
    flex-direction: column;
    &:nth-of-type(n) {
      margin-bottom: 20px;
    }
    &:last-of-type {
      margin-bottom: 30px;
    }
  }
  &--row,
  &--row__alternate > div {
    & > span {
      font-size: 14px;
      color: #999;
      font-weight: normal;
    }
    & > p {
      color: #333;
      font-weight: normal;
    }
  }
}
