.personal-data {
  width: 100%;
  height: 530px;
  border-radius: 6px;
  box-shadow: 0 0 12px 0 #00000028;
  background-color: #ffffff;

  @media only screen and (max-width: 500px) {
    width: 104%;
    margin-left: -2%;
    min-height: calc(100vh - 70px - 41.8px - 48px - 100px - 48px);
    height: 100%;
  }
  &--header {
    height: 70px;
    border-bottom: 2px solid #d1d8f599;
    padding-top: 18px;
    & > h2 {
      font-size: 18px;
      font-weight: bold;
      color: #686868;
      margin-left: 28px;
    }
  }
  &--form {
    position: relative;
    padding: 45px 55px;

    @media only screen and (max-width: 660px) {
      padding: 35px 45px;
    }
    @media only screen and (max-width: 360px) {
      padding: 20px 35px;
    }

    & > button {
      position: absolute;
      right: 31px;
      bottom: 5px;

      @media only screen and (max-width: 500px) {
        bottom: 15px;
        right: 50%;
        transform: translate(50%, -50%);
        width: calc(90% - 75px) !important;
        padding: 10px 15px;
      }
    }
    &__row {
      display: flex;
      justify-content: space-between;
      @media only screen and (max-width: 500px) {
        flex-direction: column;
        &:not(:last-of-type) {
          margin-bottom: 0px !important;
        }
        &:last-of-type {
          margin-bottom: 80px !important;
        }
      }
    }
    &__item {
      display: flex;
      flex-direction: column;
      width: 48%;
      font-size: 16px;

      @media only screen and (max-width: 500px) {
        width: 100%;
        margin-bottom: 1rem;
      }

      &__contact {
        flex-direction: row;
        & &__item {
          display: flex;
          flex-direction: column;
        }
      }
      &__contact__item > label,
      & > label {
        color: rgba(0, 0, 0, 0.54);
        transform: translate(0, 1.5px) scale(0.75);
        transform-origin: top left;
      }
      &__contact__item > span,
      & > span {
        display: block;
        color: #1a315b;
        font-size: 14px;
        font-weight: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
