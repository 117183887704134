.results-table {
  /* .MuiTableHead-root > tr > th:nth-of-type(2) {
    position: sticky;
  }
  .MuiTableHead-root > tr > th:nth-of-type(2)::after {
    display: block;
    content: 'asdf';
    height: 200px;
    width: 200px;
    background-color: red;
    position: absolute;
    top: 4%;
  } */

  @media only screen and (max-width: 1198px) {
    width: 90% !important;
  }
  @media only screen and (max-width: 778px) {
    width: 100% !important;
  }
  @media only screen and (max-width: 690px) {
    width: 120% !important;
    transform: scale(0.9) translateX(-10%);
  }
  @media only screen and (max-width: 590px) {
    width: 140% !important;
    transform: scale(0.75) translateX(-20%);
  }

  @media only screen and (max-width: 504px) {
    display: none;
  }

  .thead-with-icon {
    position: relative;
    display: flex;
    align-items: center;
    /* &:hover > .tooltip {
      display: block;
      position: absolute;
      top: -382%;
      left: 45px;
    } */
  }
  .help-icon {
    font-size: 1rem;
    color: #1a315b;
  }
  .tooltip.password-tooltip {
    display: none;
  }

  .MuiTableCell-stickyHeader {
    background-color: #ecedf0;
  }
  .MuiTableCell-stickyHeader {
    top: auto;
    left: auto;
  }

  /* BLUE ICON COLOR */
  /* Font size -- body */
  td.MuiTableCell-root.MuiTableCell-body {
    font-size: 16px;
    & > svg {
      color: #0357d3;
      cursor: pointer;
    }
  }
  /* PADDING LEFT - TABLE */
  .MuiTableRow-root.MuiTableRow-head > th:nth-of-type(1),
  .MuiTableRow-root.MuiTableRow-hover > td:nth-of-type(1) {
    padding-left: 40px;
  }

  /* PADDING TOP - TABLE */
  /* Font size -- header */
  .MuiTableRow-root.MuiTableRow-head > th {
    padding-top: 20px;
    font-size: 14px;
  }
}

.open-modal-button {
  cursor: pointer;
  border-radius: 2px;
  background-color: #637381;
  color: white;
  padding: 12px 30px;
  display: inline-block;
  transition: background-color 0.3s;
}

.open-modal-button:hover {
  background-color: #4e5a64;
}

.results-table--mobile {
  display: none;
  margin-top: 20px;

  @media only screen and (max-width: 504px) {
    display: block;
  }

  &__row {
    &__header {
      background-color: #9bc9ff;
      color: #0051af;
    }
    &__date {
      font-size: 12px;
      padding-left: 15px;
    }
    &__body {
      display: flex;
      justify-content: space-between;

      & > div {
        display: flex;
        flex-direction: column;
        padding: 14px 28px;
      }
    }
    &__label {
      color: #999;
    }
    &__payee,
    &__amount {
      color: #333;
    }
  }
}
