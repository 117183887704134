@import '../../../styles/variables.scss';

.diagonal-navbar {
  height: 10%;
  background-image: linear-gradient(to top, #45bdff 7%, #144a95);
  width: 100%;
  position: fixed;
  z-index: 99;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1em;

  // @media only screen and (max-width: 500px) {
  //   clip-path: polygon(0 0, 100% 0, 100% 30%, 0 45%);
  // }

  // & > svg {
  //   margin-left: 50px;
  //   height: 30%;

  //   @media only screen and (max-width: 500px) {
  //     margin-left: -50px;
  //     height: 7%;
  //     margin-top: -84px;
  //     transform: scale(0.8);
  //   }
  // }
}
