$layout-body-background: #ffffff;

$navbar-height: 70px;
$diagonal-navbar-height: 152px;

// Breakpoints
$xs: 0px;
$sm: 600px;
$md: 960px;
$lg: 1280px;
$xl: 1920px;
