.delete-card-modal {
  background-color: white;
  width: 650px;
  //   height: 200px;
  border-radius: 8px;
  overflow: hidden;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &--content {
    padding: 72px 30px 40px 50px;
    display: flex;
    flex-direction: column;

    & > div {
      @media only screen and (max-width: 500px) {
        display: flex;
        flex-direction: column;
      }

      & > button {
        @media only screen and (max-width: 500px) {
          margin-left: 0;
        }
        &:last-of-type {
          @media only screen and (max-width: 500px) {
            margin-top: 10px;
            margin-left: 0 !important;
          }
        }
      }
    }

    p {
      font-size: 20px;
      line-height: 2.2;
      @media only screen and (max-width: 500px) {
        font-size: 18px;
      }
    }
  }
}
