@import '../../styles/variables.scss';

.pay-your-bill {
  &__page {
    background-color: #f5f7fa;
    padding-top: $navbar-height;

    .MuiStepper-root {
      width: 55%;
      margin: 0 auto;
      min-width: 500px;
      background-color: transparent;

      @media only screen and (max-width: 660px) {
        width: 85%;
        min-width: unset;
      }
    }
    .MuiStepIcon-root {
      &.MuiStepIcon-active,
      &.MuiStepIcon-completed {
        color: #4a90e2;
      }
    }
    & .progress-bar__flag_active {
      background-color: #4a90e2;
    }
  }

  &__header {
    position: relative;
    padding: 40px;
    text-align: center;

    @media only screen and (max-width: 660px) {
      margin-bottom: 0px;
    }

    h2 {
      font-size: 28px;
      color: #1a315b;
      text-align: center;

      @media only screen and (max-width: 660px) {
        font-size: 20px;
      }
    }

    button {
      position: absolute;
      top: 50%;
      left: 30px;
      font-size: 16px;
      transform: translateY(-50%);
      color: #0357d3;
      background: transparent;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      & > svg:first-child {
        margin-right: 10px;
      }
      @media only screen and (max-width: 720px) {
        left: 15px;
      }

      @media only screen and (max-width: 750px) {
        font-size: 20px;
        left: 20px;
        & > svg {
          stroke-width: 3;
          height: 20px;
          width: 20px;
        }

        & > span:last-child {
          display: none;
        }

        & > svg:first-child {
          margin-right: 0;
        }
      }
      @media only screen and (max-width: 360px) {
        left: 10px;
      }
    }
  }

  &__sidebar {
    // background-color: red;
    width: 354px;
    // height: 500px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 0 12px 0 rgba(213, 213, 213, 0.61);

    @media only screen and (max-width: 900px) {
      height: auto;
      margin-bottom: 24px;
      width: 100%;
    }
    @media only screen and (max-width: 660px) {
      width: 95%;
      margin: 0 auto 24px;
    }

    &__header {
      height: 49px;
      //border-radius: 8px 8px 0 0;
      display: flex;
      align-items: center;
      padding: 40px;
      color: #0e172a;
      font-weight: bold;
    }

    &__content {
      // padding: 20px;
    }
    &__content p {
      font-size: 15px;
      text-align: center;
      padding: 15px;
      color: #53bfea;
      font-weight: 600;
    }

    &__row {
      display: flex;
      flex-direction: column;
      &.sum {
        align-items: flex-end;
      }
    }
    &__label {
      font-size: 12px;
      color: #666;
      text-align: left;

      &.sum {
        font-size: 20px;
        text-align: right;
        padding-bottom: 20px;
      }
    }
    &__value {
      color: #1a315b;
      text-align: left;
      font-weight: 500;
      &.sum {
        font-size: 33px;
        text-align: right;
        font-weight: bold;
        margin-top: -16px;
      }
    }
  }
  &__scan-code-banner {
    display: none;
    @media only screen and (max-width: 660px) {
      display: flex;
    }
    align-items: center;
    justify-content: space-between;
    background: white;
    padding: 8px 12px;
    height: 55px;
    width: 98%;
    margin: 0 auto 20px;
    border: 1px solid #4a90e2;

    &__label {
      color: #4a90e2;
      font-size: 12px;
    }
    &__icon {
      color: #3c3c3c;
      &__container {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        box-shadow: 0 2px 4px 0 #1a315b;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
