@import '../../styles/variables.scss';
.main-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: $layout-body-background;
  max-width: 1440px;
  margin: 0 auto;
  /* padding-top: 80px; */
  .page-content {
    flex: 1;
  }
}

.footerSecure {
  font-size: 12px;
  color: #4a90e2;
  overflow: hidden;
}
.footer0 {
  float: left;
  @media only screen and (max-width: 575px) {
    font-size: 12px;
    text-align: center;
    width: 100%;
  }
  @media only screen and (max-width: 448px) {
    font-size: 9px;
    text-align: center;
    width: 100%;
  }
}
.footer1 {
  float: left;
  @media only screen and (max-width: 575px) {
    font-size: 12px;
  }
  @media only screen and (max-width: 448px) {
    font-size: 9px;
  }
}
.footer2 {
  float: right;
  @media only screen and (max-width: 575px) {
    font-size: 12px;
  }
  @media only screen and (max-width: 448px) {
    font-size: 9px;
  }
}

footer {
  padding: 2em;

  @media only screen and (max-width: 575px) {
    font-size: 12px;
  }
  @media only screen and (max-width: 448px) {
    font-size: 9px;
  }
}
