.scan-code-modal {
  &__header {
    background: #4a90e2;
    height: 82px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
  }

  &__tab {
    width: 50%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;

    transition: background 0.3s;

    &:hover:not(&--active) {
      background: #fff2;
    }

    &__container {
      width: 85%;
      height: 60%;
      display: flex;
      //   margin: 0 auto;
      border-radius: 8px;
      border: 1px solid white;
      cursor: pointer;
    }

    &--left {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &--right {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &--active {
      background: white;
      color: #4a90e2;
    }
  }

  &__content {
    // margin-top: 57px;
    &--automatic {
      video {
        height: 100vh;
        // width: 100vw;
        // margin-top: -57px;
        object-fit: cover;
      }
    }

    &--manual {
      padding-top: 107px;

      // background: #0003;
    }
  }
  &__popup {
    background: #e9e9e9;
    // width: 270px;
    // height: 165px;
    width: 70%;
    min-width: 270px;
    border-radius: 8px;
    margin: 20px auto;
    padding: 16px 0 0;

    &__label {
      color: #030303;
      font-size: 17px;
      letter-spacing: -0.41px;
      text-align: center;
      display: block;
      margin: 38px auto 16px;
    }
    &__input {
      border: solid 0.5px #4d4d4d;
      background: white;
      border-radius: 0;
      margin: 0 auto;
      display: block;
      width: 90%;
      height: 24px;
    }
    &__footer {
      display: flex;
      height: 53px;

      &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 50%;
        border-top: 1px solid #4d4d4d;
        margin-top: 12px;
        color: #007aff;
        &:nth-of-type(2n + 1) {
          border-right: 1px solid #4d4d4d;
          border-bottom-left-radius: 12px;
        }

        &:nth-of-type(2n + 2) {
          border-bottom-right-radius: 12px;
        }

        &:hover {
          background: #cccccc;
        }
        &:active {
          background: #cccccc;
        }
      }
    }
  }

  &__shutter {
    border-width: 4px;
    border-color: white;
    width: 50px;
    height: 50px;
    position: absolute;
    // transform: translate(-50%, -50%);

    &__container {
      position: absolute;
      //   background: red;
      width: 70%;
      max-width: 300px;
      height: 140px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &--top-left {
      border-left: 10px solid white;
      border-top: 10px solid white;
      left: 0;
      top: 0;
    }

    &--top-right {
      border-right: 10px solid white;
      border-top: 10px solid white;
      right: 0;
      top: 0;
    }
    &--bottom-left {
      border-left: 10px solid white;
      border-bottom: 10px solid white;
      left: 0;
      bottom: 0;
    }
    &--bottom-right {
      border-right: 10px solid white;
      border-bottom: 10px solid white;
      right: 0;
      bottom: 0;
    }
  }
  &__label {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2px 18px;
    background: #0003;
    color: white;
    white-space: nowrap;
    // width: 70%;
    // min-width: 300px;
    font-size: 12px;
    border-radius: 4px;
    text-align: center;
  }

  &__info-screen {
    // height: 500px;
    text-align: center;
    background: white;
    padding-bottom: 20px;
    &__header {
      height: 70px;
      background: #1a315b;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
    }

    &__img {
      width: 70%;
      margin-top: 24px;
    }

    &__paragraph {
      text-align: left;
      width: 90%;
      margin: 20px auto;
      font-size: 14px;
      color: #333;
    }

    &__label {
      color: #333;
      font-size: 14px;
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px;
    }
    &__button {
      width: 33%;
      font-weight: bold;
      text-transform: uppercase;
      border: none;
      color: white;
      display: block;
      height: 40px;
      border-radius: 4px;
      font-size: 18px;
      &--cancel {
        background: #b9b9b9;
      }
      &--ok {
        background: #0297db;
      }
    }
  }
}
