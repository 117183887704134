.cookies-alert {
  background-color: #4a90e2;
  padding: 13px 21px;
  color: white;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 718px;
  @media only screen and (max-width: 740px) {
    width: 100%;
  }
}

.cookies-alert > p {
  width: 98%;
  font-size: 14px;
}

.cookies-alert > svg {
  font-size: 16px;
  letter-spacing: 0.15px;
  position: absolute;
  right: 12px;
  top: 8px;
  cursor: pointer;
}
